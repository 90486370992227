import { Elm } from "./src/Main.elm";
import "./scss/style.scss";

if (module.hot) {
  module.hot.dispose(() => {
    window.location.reload();
  });
}

Sentry.init({ dsn: 'https://3ace96fb3f9041439381128e4e042ea3@sentry.io/1880220' });


const flags = {};

// Handler when the DOM is fully loaded
const app = Elm.Main.init({ flags });

app.ports.scrollTop.subscribe(function(_) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
});

app.ports.doLogError.subscribe(function(data) {
  if (Sentry) {
    Sentry.captureMessage(data)
  } else {
    console.log("Cannot capture error");
  }
});

var el = document.body.querySelector('div[style*="z-index: 2147483647')
if (el) {
  el.style.left = '-200000px'
}

